<template>
  <div class="page-info-content">
    <div class="details-header">
      <span class="header-sign">订</span>
      订单号{{ detailObj.tradeInfo.orderNo }}
      <span class="header-status">{{ getDictName($store.getters.getDictionaryItem("BILL_ORDER_STATUS"), detailObj.tradeInfo.billOrderStatus) }}</span>
    </div>
    <DetailsPage
      v-if="detailObj.billInfo.billNo"
      class="interval"
      details-title="票据信息"
      :detail-obj="detailObj.billInfo"
      :details-item-arr="billDetailItemArr"
      :column="3"
      border
    />
    <DetailsPage
      class="interval"
      details-title="交易信息"
      :detail-obj="detailObj.tradeInfo"
      :details-item-arr="businessDetailItemArr"
      :column="3"
      border
    />
    <div class="interval">
      <h3 class="page-subtitle-shade">
        收益明细
      </h3>
      <div class="accumulated-income">
        累计收益(元)
        <el-tooltip effect="dark" content="仅加总审核通过的收益金额" placement="top-start">
          <i class="el-icon-warning-outline" />
        </el-tooltip>
        <span>+{{ numberToCurrency(detailObj.tradeInfo.accumulatedIncome) }}</span>
      </div>
      <div class="table-outer-frame">
        <Table
          :item-data="incomeItemData"
          :list-data="detailObj.incomeInfoList"
          :operation-button="incomeOperationButton"
        >
          <template #flowAmount="{row}">
            <span :class="row.billOrderAuditResult == 2 ? 'income-red' : ''">+{{ numberToCurrency(row.flowAmount) }}</span>
          </template>
          <template #billOrderAuditResult="{row}">
            <span :class="row.billOrderAuditResult == 1 ? 'income-blue' : row.billOrderAuditResult == 2 ? 'income-green' : 'income-orange'">{{ getDictName($store.getters.getDictionaryItem("BILL_ORDER_AUDIT_RESULT"), row.billOrderAuditResult) }}</span>
          </template>
        </Table>
      </div>
    </div>
    <div class="interval">
      <h3 class="page-subtitle-shade">
        操作记录
      </h3>
      <div class="table-outer-frame">
        <Table
          :item-data="operationItemData"
          :list-data="detailObj.operateRecordList"
        >
          <template #fileName="{ row }">
            <div class="file-box">
              <span class="file-name">{{ row.fileName }}</span>
              <span v-if="row.fileName" class="file-operation" @click="viewIncomeFile({fileUrl: row.fileUrl})">查看</span>
              <span v-if="row.fileName" class="file-operation" @click="downloadOperation(row.fileUrl, row.fileName)">下载</span>
            </div>
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import Table from '@/components/Table.vue'
import { operateGetBillOrderDesc } from '@/api/piaoju.js'
import { downloadFile, getDictName, numberToCurrency, plusXing } from '@/utils/util.js'
export default {
  components: { DetailsPage, Table },
  data() {
    return {
      getDictName,
      numberToCurrency,
      detailObj: {
        tradeInfo: { accumulatedIncome: 0 }
      },
      // 票据信息配置
      billDetailItemArr: [
        { label: '票据号码', value: 'billNo', router: '/piaoju/detailBill', id: 'id' },
        { label: '出票人', value: 'drawer' },
        { label: '承兑人', value: 'acceptor' },
        { label: '收款人', value: 'payee' },
        { label: '出票日期', value: 'drawerDate' },
        { label: '到期日期', value: 'expireDate' },
        { label: '票面金额', money: 'faceAmount', rightWords: '元' }
      ],
      // 交易信息配置
      businessDetailItemArr: [
        { label: '客户名称', value: 'userName' },
        { label: '联系电话', value: 'phoneNo' },
        { label: '身份证号', value: 'cardNo' },
        { label: '购买金额', money: 'buyAmount', rightWords: '元' },
        { label: '年化收益率', value: 'annualizedRevenueRate', rightWords: '%' },
        { label: '起息日期', value: 'beginDate' },
        { label: '到期日期', value: 'expireDate' },
        { label: '期限', value: 'term', rightWords: '天' },
        { label: '预期总收益', money: 'expectedTotalRevenue', rightWords: '元' },
        { label: '业务员', value: 'busiUserName' },
        { label: '业务员手机号', value: 'busiPhoneNo' }
      ],
      // 收益明细table配置
      incomeItemData: [
        { label: '创建时间', prop: 'createTime', width: 160 },
        { label: '发放日期', prop: 'flowDate', width: 160 },
        { label: '发放收益', prop: 'flowAmount', type: 'money', width: 160 },
        { label: '审核结果', prop: 'billOrderAuditResult', width: 160, child: this.$store.getters.getDictionaryItem('BILL_ORDER_AUDIT_RESULT') },
        { label: '备注', prop: 'remarks', width: 160 }
      ],
      incomeOperationButton: [
        { child: [{ val: 'billOrderAuditResult', num: 2 }], bType: 'primary', label: '查看凭证', handleEvent: this.viewIncomeFile }
      ],
      // 操作记录table配置
      operationItemData: [
        { label: '操作项目', prop: 'billOrderOperStatus', child: this.$store.getters.getDictionaryItem('BILL_ORDER_OPER_STATUS'), width: 160 },
        { label: '操作人员', prop: 'operUserName', width: 160 },
        { label: '操作时间', prop: 'operTime', width: 160 },
        { label: '备注', prop: 'remarks', width: 160 },
        { label: '附件', prop: 'fileName', width: 160 }
      ]
    }
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    // 获取详情数据
    getDetails() {
      if (this.$route.query.id) {
        operateGetBillOrderDesc(this.$route.query.id, res => {
          res.data.operateRecordList.forEach(item => {
            item.billOrderOperStatus = String(item.billOrderOperStatus)
            item.operUserName = item.operUserName + ' (' + item.operUserCode + ')'
          })
          this.detailObj = res.data
          this.detailObj.tradeInfo.cardNo = plusXing(res.data.tradeInfo.cardNo, 3, 1)
        })
      }
    },
    // / 查看文件
    viewIncomeFile({ fileUrl }) {
      const newFileUrl = fileUrl || this.dialogForm.oldMaterialUrl
      window.open(this.$fileUrl + newFileUrl)
    },
    // 下载
    downloadOperation(url, name) {
      downloadFile(this.$fileUrl + url, name)
    }
  }
}
</script>

<style lang="scss" scoped>
.details-header {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 64px;

  .header-sign {
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #94C8FF;
    color: #fff;
    font-size: 24px;
    margin-right: 24px;
  }

  .header-status {
    width: 86px;
    height: 28px;
    padding: 4px 8px;
    border-radius: 2px;
    background: #0172eb0d;
    font-size: 14px;
    color: $mainBlue;
    margin-left: 16px;
  }
}
.interval {
  margin-bottom: 56px;
}

.accumulated-income {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  .el-tooltip {
    padding-top: 3px;
    padding-left: 5px;
    cursor: pointer;
  }
  span {
    font-size: 24px;
    font-weight: 500;
    color: $mainBlue;
    margin-left: 10px;
  }
}
.sub-title {
  position: absolute;
  top: 22px;
  left: 100px;
  color: #666;
}
.view-old-material {
  color: $mainBlue;
  margin-left: 20px;
  cursor: pointer;
}
.file-box {
  display: flex;
  flex-wrap: nowrap;
  .file-name{
    width: 68%;
    word-wrap:break-word;
    white-space:normal;
  }
  .file-operation {
    flex-shrink: 0;
    display: inline-block;
    color: $mainBlue;
    margin-left: 10px;
    cursor: pointer;
  }
}
.income-red {
  color: red;
}
.income-green {
  color: #09AF42;
  border-radius: 4px;
  background-color: #E7F6ED;
  padding: 4px 8px;
}
.income-blue {
  color: #0172EB;
  border-radius: 4px;
  background-color: #E5F0FC;
  padding: 4px 8px;
}
.income-orange {
  color: #F44C27;
  border-radius: 4px;
  background-color: #FFEDEA;
  padding: 4px 8px;
}
.table-outer-frame {
  max-height: 446px;
  overflow: auto;
}
</style>
